import React, { createContext, useState } from "react";

export const Context = createContext();

const AppState = ({ children }) => {
  const [show, setShow] = useState(false);
  
  const [logindetails, setLogInDetails] = useState(() => {
    const userId = localStorage.getItem('user_id');
    const userApiKey = localStorage.getItem('user_api_key');
    return userId && userApiKey 
      ? { user_id: userId, user_api_key: userApiKey } 
      : {};
  });
  console.log(logindetails);
  

  const login = (user_id, user_api_key) => {
    const details = { user_id, user_api_key };
    setLogInDetails(details);
    localStorage.setItem('user_id', user_id);
    localStorage.setItem('user_api_key', user_api_key);
  };

  const logout = () => {
    setLogInDetails({});
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_api_key');
  };

  return (
    <Context.Provider value={{ show, setShow, logindetails, setLogInDetails, login, logout }}>
      {children}
    </Context.Provider>
  );
};

export default AppState;
