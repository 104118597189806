import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Layout from "../Component/Layout";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import { FaSearch } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import "../css/dashboard.scss";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/searchPage.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Dashboard() {
  const [orderList, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [membersPerPage, setMembersPerPage] = useState(10);
  const [activeMembers, setActiveMembers] = useState([]);
  const [inactiveMembers, setInactiveMembers] = useState([]);
  const [filterStatus, setFilterStatus] = useState("All");
  const [status, setStatus] = useState({});
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectedMemberId(id);
    setShow(true);
  };

  const notify = () => toast("Member deleted successfully");

  const handleFilterChanges = (e) => {
    setFilterStatus(e.target.value);
  };

  // Move to edit page  
  const moveToEdit = (editMember) => {
    navigate(`/edit_member/${editMember.u_id}`, { state: { editMember } });
  }

  const filteredMembers = orderList.filter((member) => {
    if (filterStatus === "All") {
      return true;
    }
    return member.u_status === filterStatus;
  });

  const searchedMembers = filteredMembers.filter((member) =>
    member.u_referal_id.toLowerCase().includes(query.toLowerCase())
  );

  const email = location.state || {
    email: localStorage.getItem("admin_mail"),
    useID: localStorage.getItem("user_id"),
  };

  const userId = email.useID;
  const admin_mail = email.email;

  useEffect(() => {
    if (email.email) localStorage.setItem("admin_mail", email.email);
    if (email.useID) localStorage.setItem("user_id", email.useID);
  }, []);

  const lastPostIndex = currentPage * membersPerPage;
  const firstPageIndex = lastPostIndex - membersPerPage;
  const pageInView = searchedMembers.slice(firstPageIndex, lastPostIndex);

  const totalMembers = searchedMembers.length;
  const totalPages = Math.ceil(totalMembers / membersPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const changeStatus = async (status, member_id) => {
    try {
      const params = {
        status: status,
        user_id: member_id,
      };

      await axios.post(
        "https://lunarsenterprises.com:3004/mlm/status-update",
        params,
        {
          headers: { u_id: userId },
        }
      );
      setStatus((prevStatuses) => ({
        ...prevStatuses,
        [member_id]: status,
      }));
      fetchAllMembers();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllMembers = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user-list",
        { user_id: userId }
      );
      
      const members = response.data.list;
      setOrderList(members);

      const active = members.filter((member) => member.u_status === "active");
      const inactive = members.filter(
        (member) => member.u_status === "inactive"
      );
      setInactiveMembers(inactive);
      setActiveMembers(active);
    } catch (error) {
      console.log(error);
    }
  };

  // Delete a member
  const deleteUser = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/delete",
        {
          u_id: selectedMemberId 
        },
        {
          headers: {
            user_id: userId,
            api_key: email.key
          }
        }
      );
      if (response.data.result === true) {
        notify();
      }
      fetchAllMembers();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchAllMembers();
  }, []);

  return (
    <Layout>
      <div className="status-box">
        <div className="status-active">
          <h6>{activeMembers.length}</h6>
          <h5>Active Members</h5>
        </div>
        <div className="status-inactive">
          <h6>{inactiveMembers.length}</h6>
          <h5>Inactive Members</h5>
        </div>
        <div className="income-generated">
          <h6>{activeMembers.length * 150}</h6>
          <h5>Income Received</h5>
        </div>
      </div>

      <div className="filter-box">
        <select
          onChange={handleFilterChanges}
          value={filterStatus}
        >
          <option value="All">All</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
      
      <div className="search-box">
        <FaSearch id="search-icon" />
        <input
        id="search"
          type="text"
          placeholder="search member referral id"
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />
      </div>

      <div className="mt-5">
        <div className="table-responsive d-flex pe-3 ps-3 pb-0 bgWhite">
          <table
            className="table table-hover custom-table table-borderless"
            style={{ minWidth: "700px" }}
          >
            <thead className="bordertablesho">
              <tr className="line2px-green">
                <th scope="col">Sr No</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Referral No</th>
                <th scope="col">Status</th>
                <th scope="col">Change</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {searchedMembers.length > 0 ? (
                pageInView.map((order, index) => (
                  <tr className="line1px" key={order.id}>
                    <th scope="row">{firstPageIndex + index + 1}</th>
                    <td>{order.u_first_name}</td>
                    <td>{order.u_mobile}</td>
                    <td>{order.u_referal_id}</td>
                    <td>{order.u_status}</td>
                    <td>
                      <select
                        onChange={(e) =>
                          changeStatus(e.target.value, order.u_id)
                        }
                        value={order.u_status}
                        style={{ border: "none", outline: "none" }}
                      >
                        <option value="All">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>
                    <td onClick={() => moveToEdit(order)}>
                      <MdModeEdit size={20} />
                    </td>
                    <td onClick={() => handleShow(order.u_id)}>
                      <MdDelete size={20} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">No members found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-center mt-5 mb-5">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this member?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </Layout>
  );
}

export default Dashboard;
