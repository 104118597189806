import React, { useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import OTPInput from "react-otp-input";
import Logo from "../img/main_logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/Context";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SignIn() {
  const navigate = useNavigate();
  const handleOtpClose = () => setShowOtp(false);
  const handleOtpOpen = () => setShowOtp(true);
  const [showOtp, setShowOtp] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [otp, setOtp] = useState("");
  const { setLogInDetails, logindetsils } = useContext(Context);

  const [formInput, setFormInput] = useState({
    device_id: "1",
    device_os: "1",
    device_token: "1",
    email: "",
    password: "",
    app_version: "1",
  });

  const notify = () => toast.error("User email or password is wrong");

  const handleSignIn = async (e) => {
    e.preventDefault();

    const updatedFormInput = { ...formInput, email, password };
    setFormInput(updatedFormInput);

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/login",
        updatedFormInput
      );

      console.log(response.data);
      setLogInDetails(response.data);

      if (response.data.result === false) {
        notify();
        navigate("SignIn");
      } else {
        navigate("/DashBoard", {
          state: {
            email: response.data.user_email,
            useID: response.data.user_id,
            key:response.data.user_api_key
          },
        });
      }

      // Handle successful response
    } catch (error) {
      console.error(error);
      setShowMessage(true);
      notify(); // Show toast notification for any error
    }
  };

  return (
    <div className="login-main container-fluid">
      <div className="signinstyle">
        <img
          src={Logo}
          className="redlogo"
          alt="Red Logo"
          height="75px"
          width="150px"
        />
        <p
          className="mb-4"
          style={{
            color: "#7AB730",
            fontWeight: "bold",
            fontSize: "40px",
            textAlign: "center",
          }}
        >
          Sign In
        </p>

        <div className="login-wrapper-inner">
          <div className="mt-4 input-wrapper-login">
            <div className="icondirection icon-input-login"></div>
            <input
              type="text"
              name="email"
              className="form-control login-input"
              placeholder="Enter User Name"
              aria-label="Enter User Name"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mt-4 input-wrapper-login">
            <div className="icondirection icon-input-login"></div>
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              className="form-control login-input"
              placeholder="Enter Password"
              aria-label="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="password-toggle-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </div>
          </div>
          <div className="text-center mt-3">
            <a
              href="#"
              className="text-decoration-none text-black colorforgot"
              onClick={handleShow}
            >
              Forgot Your Password?
            </a>
            <div className="linestyless d-flex justify-content-center align-items-center mt-2"></div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button className="btnsign" onClick={handleSignIn}>
              SIGN IN
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Forgot Password?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-4 input-wrapper-login">
            <label style={{ marginLeft: 0, marginBottom: 10 }}>Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Email"
              aria-label="Enter User Name"
            />
            <label style={{ marginLeft: 0, marginTop: 10 }}>
              OTP sent to email
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleClose();
              handleOtpOpen();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showOtp}
        onHide={handleOtpClose}
        dialogClassName="modal-dialog-centered"
        aria-labelledby="otp-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="otp-modal-title">OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center justify-content-center mt-2">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            inputStyle={{
              width: "3rem",
              height: "3rem",
              fontSize: "2rem",
              borderRadius: 4,
              border: "3px solid red",
            }}
          />
          <div>
            <div className="text-center mt-3">
              {/* Timer or Resend OTP button can go here */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleOtpClose}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {showMessage && (
        <div
          className="success-message"
          style={{
            zIndex: "9999999",
            backgroundColor: "red",
            textAlign: "center",
            position: "fixed",
            top: "10px",
            left: "0",
            width: "100%",
            height: "50px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
          }}
        >
          Enter valid credentials
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default SignIn;
