import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "../src/css/main.scss";
import "../src/css/bootstrap.min.css";
import Sidebar from "./Component/Sidebar";
import Header from "./Component/Header";
import Dashboard from "./Screens/Dashboard";
import SignIn from "./Screens/Signin";
import AppState from "./context/Context";
import ProductManagement from "./Screens/AddIncome";
import Editmember from "./Screens/Editmember";
import Addproduct from "./Screens/AddProduct";
import Productlist from "./Screens/Productlist";
import Withdrawal from "./Screens/Withdrawal";

function Main() {
  const location = useLocation();
  const currPath = location.pathname;

  return (
    <div className="">
      <div>
        {currPath === "/SignIn" ? (
          <SignIn />
        ) : (
          <>
            <Sidebar />
            <Header />
          </>
        )}
      </div>

      <div className="">
        <Routes>
          <Route path="/" name="SignIn" element={<Navigate to="/SignIn" />} />
          <Route path="/DashBoard" element={<Dashboard />} />
          <Route path="/add_income" element={<ProductManagement />} />
          <Route path="/add_product" element={<Addproduct />} />
          <Route path="/product_list" element={<Productlist />} />
          <Route path="/withdraw" element={<Withdrawal />} />
          <Route path="/edit_member/:editMember" element={<Editmember />} />
          
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <AppState>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </AppState>
  );
}

export default App;
